import { BeanTaxeDetail } from "./beanTaxeDetail";

export class BeanCreationBien{
    taxe : BeanTaxeDetail[];
    idmag: number;
    numero: String;
    idsit: number;
    superficie: number;
    denomination: String;
    odp: number;
    dates: string;

    // Methd :
    constructor(){}
}
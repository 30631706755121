export class StatGenLib{

    idpro : Number;
    mois : Number;
    total : Number;
    code : String;
    libmois : String;

    // Methd :
    constructor(){}
}
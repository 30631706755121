export class ClientRest{
    
    idcli : number;
    nom : String;
    prenom : String;
    contact : String;
    email : String;
    civilite : number;
    activite : number;
    situationmaritale : number;
    datenaissance : String;
    contactdeux : String;

    // Added on 13/05/2014 :
    origine : number;
    observation : String;

    // Methd :
    constructor(){}
}
export class PerformanceObjet{

    collabo : String;
    choix : number;
    annee : number;
    mois : number;
    valeur : number;
    idper : number;

    constructor(){}
}
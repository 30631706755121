export class Reunion{
    idreu : number;
    personnes : String;
    objet : String;
    emplacement : String;
    dates : Date;
    heuredebut : String;
    heurefin : String;
    duree : number;
    rappel : number;
    resume : String;
    rapport : String;
    iduser : number;

    constructor(){}
}
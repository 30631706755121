export class InvitationRequest{
    
    commercial : string;
    client : string;    
    dates : string;    
    heure : string;    
    secteur : string;    
    motif : string;    
    idrdv : string;    
    choix : string;    
    reponse : string;    

    // Methd :
    constructor(){}
}
import { Component, OnInit } from '@angular/core';
import { Beanexportjournal } from 'src/app/mesbeans/beanexportjournal';
import { Beanpaiementretard } from 'src/app/mesbeans/beanpaiementretard';
import { Beanpaymentstats } from 'src/app/mesbeans/beanpaymentstats';
import { MeswebservService } from 'src/app/messervices/meswebserv.service';
import * as moment from 'moment';

declare const $: any;

@Component({
  selector: 'app-statistiques',
  templateUrl: './statistiques.component.html',
  styleUrls: ['./statistiques.component.css']
})
export class StatistiquesComponent implements OnInit {

  // A T T R I B U T E S  :
  bstats = new Beanpaymentstats();
  listeRetard: Beanpaiementretard[];
  listeJour: Beanpaiementretard[];
  listeJournal: Beanexportjournal[];
  getRetard = true;
  getJour = true;
  getJournal = true;
  getRec = false;
  idmois = 0;
  idannee = 0;
  typerapport = 1;
  modelerapport = "1";


  // M E T H O D S :
  constructor(private meswebservices: MeswebservService) { }

  ngOnInit(): void {
    // current month :
    this.idmois = (new Date().getMonth()) + 1;
    this.idannee = new Date().getFullYear();
    this.getpaymentstatistics();
    this.getmagasinpaiementretard();
    this.getmagasinpaiementjour();
    this.getcurrentdayjournal();
  }

  /**/
  getpaymentstatistics(): void {
    this.meswebservices.getpaymentstatistics().toPromise()
      .then(
        resultat => {
          // Succes
          this.bstats = resultat;
          this.getRec = true;
        }
      )
  }

  getmagasinpaiementretard(): void {
    this.meswebservices.getmagasinpaiementretard().toPromise()
      .then(
        resultat => {
          // Succes
          if (resultat.length > 0) {
            this.listeRetard = resultat;
          }

          this.getRetard = true;
          setTimeout(function () {
            $('#datatableretard').DataTable({
              "columnDefs": [
                { "width": "20%", "targets": 0 }
              ],

              "pagingType": "full_numbers",
              "lengthMenu": [
                [10, 25, 50, -1],
                [10, 25, 50, "All"]
              ],
              responsive: true,
              language: {
                search: "_INPUT_",
                searchPlaceholder: "Search records",
              }
              , "order": [[2, "desc"]]
            });
          }, 1000);
        }
      )
  }

  getmagasinpaiementjour(): void {
    this.meswebservices.getmagasinpaiementjour().toPromise()
      .then(
        resultat => {
          // Succes
          if (resultat.length > 0) {
            this.listeJour = resultat;
          }

          this.getJour = true;
          setTimeout(function () {
            $('#datatablejour').DataTable({
              /*"columnDefs": [
                { "width": "20%", "targets": 0 }
              ],*/

              "pagingType": "full_numbers",
              "lengthMenu": [
                [10, 25, 50, -1],
                [10, 25, 50, "All"]
              ],
              responsive: true,
              language: {
                search: "_INPUT_",
                searchPlaceholder: "Search records",
              }
              , "order": [[2, "desc"]]
            });
          }, 1000);
        }
      )
  }


  getcurrentdayjournal(): void {
    this.meswebservices.getcurrentdayjournal().toPromise()
      .then(
        resultat => {
          // Succes
          if (resultat.length > 0) {
            this.listeJournal = resultat;
          }

          this.getJournal = true;
          setTimeout(function () {
            $('#datatablejournal').DataTable({
              /*"columnDefs": [
                { "width": "20%", "targets": 0 }
              ],*/

              "pagingType": "full_numbers",
              "lengthMenu": [
                [10, 25, 50, -1],
                [10, 25, 50, "All"]
              ],
              responsive: true,
              language: {
                search: "_INPUT_",
                searchPlaceholder: "Search records",
              }
              , "order": [[2, "desc"]]
            });
          }, 1000);
        }
      )
  }

  // Now,  :
  displayReportWindow() {
    $('#modalrapport').modal('show');
  }

  /*onYearChange(){
    if(this.idannee > new Date().getFullYear()){
      // Keep it :
      this.idannee = new Date().getFullYear();
    }
  }*/

  // Now, call to gnerate REPORT :
  generateReport() {
    // Set values : 
    switch(this.typerapport){
      case 1:
        // Rapport Journalier
        this.meswebservices.getdailypaymentreport().toPromise().then(
          resultat => {
            let file = new Blob([resultat], { type: 'application/octet-stream' });
            let fileUrl = window.URL.createObjectURL(file);
            const link = document.createElement('a');
            link.href = fileUrl;
    
            var filename = "rapportJournalier.xlsx";
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
          },
          (error) => {
            alert("Problème survenu !")
          }
        );
        break;

      case 2:
        // Recouvrement mensuel
        var requestReport = new FormData();
        requestReport.append("mois", this.idmois.toString());
        requestReport.append("annee", this.idannee.toString());

        this.meswebservices.getMonthlyPaymentReport(requestReport).toPromise().then(
          resultat => {
            //
            let file = new Blob([resultat], { type: 'application/octet-stream' });
            let fileUrl = window.URL.createObjectURL(file);
            const link = document.createElement('a');
            link.href = fileUrl;
    
            var filename = "rapportMensuel_"+this.idannee.toString()+"_"+this.idmois.toString()+".xlsx";
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
          },
          (error) => {
            alert("Problème survenu !")
          }
        );
        break;
    }
    
  }

}

export class ReunionObjet{

    personnes : String;
    objet : String;
    emplacement : String;
    dates : String;
    heuredebut : String;
    heurefin : String;
    duree : number;
    rappel : number;
    resume : String;
    rapport : String;

    constructor(){}
}
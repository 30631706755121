export class ActivitecommercialeRest{
    idacm : number;
    datedebut : String;
    datefin : String;   
    libelle : String;
    responsable : number;
    chefequipe : number;
    objectifchiffre : number;
    produit : number;
    iduser : number;

    constructor(){}
}
export class Rapport{
    idrdv : number;
    idrap : number;
    dates : String;
    heure : String;
    contenu : String;
    actions : String;
    // Added on 13/07/2021
    interlocuteurs : String;

    constructor(){}
}
export class RdvFullRest{
    idrdv : number;
    nom : String;
    contact : String;
    email : String;
    activite : number;
    motif : number;
    dates : Date;
    heure : String;
    categorie : number;
    qualite : number;
    superviseur : number;
    resume : String;
    etat : number;
    idcom : number;
    //
    invite : String;
    nomfonction : String;
    mailautre : String;
    lieu : String;

    constructor(){}
}
export class StatsDevisUser{

    auto : string;
    voyage : string;
    accident : string;
    mrh : string;
    sante : string;
    total : string;

    constructor(){}
}
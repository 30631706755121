export class Parametrage{
    
    idpar : number;
    prixmetrecarre : number;   
    trimestreun : number;   
    trimestredeux : number;   
    trimestretrois : number;   
    trimestrequatre : number;   


    // Methd :
    constructor(){}
}
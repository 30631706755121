export class Professeur{

    idprof : Number;
    nom : string;
    prenom : string;    
    contact : string;    
    email : string;    

    // Methd :
    constructor(){}
}
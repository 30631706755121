export class DataGrapheCours{

    cours : string;
    mois : Number;
    tot : number;
    id: Number;
    libmois : string;

    // Methd :
    constructor(){}
}
export class Responsable{

    idres : Number;
    nom : string;
    prenom : string;    
    contact : string;    
    email : string;    

    // Methd :
    constructor(){}
}